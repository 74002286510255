/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$HCU.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$ParentID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$PatientTypeID.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$Status.json';
import schema_4 from './com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$XeVisitCaseManagementProgram.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$IVID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DerivedCaseIVID":{"type":"string"},"DerivedCaseVisitID":{"type":"string"},"DerivedProgram":{"type":"string"},"DerivedProgramID":{"type":"string"},"DerivedProgramIVID":{"type":"string"},"DerivedProgramVisitID":{"type":"string"},"DisplayRoomBed":{"type":"string","maxLength":50},"HCU":{"$ref":"com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$HCU.json#"},"IVID":{"type":"integer","primaryKey":"generated"},"IVIDOperator":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ParentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$ParentID.json#"},"ParentIDTableIdentifier":{"type":"string"},"PatientTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$PatientTypeID.json#"},"RestrictValue":{"maximum":99999,"type":"integer"},"StartDate":{"format":"local-date-time","nullable":true,"type":"string"},"StartDateOff":{"type":"string"},"Status":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$Status.json#"}]},"TableIdentifier":{"type":"string"},"VisitID":{"type":"string","maxLength":60},"VisitTypeID":{"type":"string","maxLength":16},"XeVisitCaseManagementProgram":{"$ref":"com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$XeVisitCaseManagementProgram.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$IVID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$IVID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentRequest.json#"},"AssessmentID":{"type":"integer"},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DataAsset":{"type":"object"},"ExternalID":{"type":"string","maxLength":50},"IPID":{"type":"integer","primaryKey":"supplied"},"IVID":{"type":"integer","primaryKey":"generated"},"IsQuickSave":{"type":"string","maxLength":1},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OrderID":{"type":"integer"},"OverrideQuickSave":{"defaultValue":false,"type":"boolean"},"PlacerID":{"type":"string","maxLength":100},"PlacerSystem":{"type":"string","maxLength":50},"QuickSaveOwner":{"type":"integer"},"RenderType":{"type":"string","maxLength":30},"ReturnID":{"type":"boolean"},"SignedBy":{"type":"integer"},"SourceSystem":{"type":"string","maxLength":100},"Status":{"type":"string","maxLength":32},"Version":{"maximum":9999,"type":"integer"},"VersionFileID":{"type":"integer"},"VersionID":{"maximum":99,"type":"integer"},"VisitAssessmentID":{"type":"integer","primaryKey":"generated"},"WellKnownID":{"type":"string","maxLength":50}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideline$XeVisitGuidelineSum.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideline.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"ExternalID":{"type":"string","maxLength":100},"GuidelineCode":{"type":"string","maxLength":100},"IVID":{"type":"integer"},"LinkID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":200},"Publication":{"type":"string","maxLength":100},"VisitGuidelineID":{"type":"integer","primaryKey":"generated"},"XeVisitGuidelineSum":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideline$XeVisitGuidelineSum.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideline.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideline.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

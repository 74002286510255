/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess$VisitAssessmentID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess$XeVisitCorrespAttach.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"FileID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"QuerySort":{"type":"string"},"ReportID":{"type":"integer"},"SequenceNo":{"maximum":9999,"type":"integer"},"UsageFlag":{"maximum":9999,"type":"integer"},"UsageFlagFilter":{"type":"string"},"UsageFlagOperator":{"type":"string"},"VisitAssessmentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess$VisitAssessmentID.json#"},"VisitCorrespAttachmentID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess$XeVisitCorrespAttach.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess$XeVisitCorrespAttach.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

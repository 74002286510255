/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$XeCorrespondenceInfo.json#"},"AllowCoverSelect":{"type":"boolean"},"CorrespondenceCode":{"type":"string","maxLength":50},"CorrespondenceInfoID":{"type":"integer","primaryKey":"supplied"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DefaultFaxCoverWKID":{"type":"string","maxLength":50},"DefaultMailCoverWKID":{"type":"string","maxLength":50},"IsSelectable":{"type":"boolean"},"LetterType":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"ReportCode":{"type":"string","maxLength":50},"ReportFileName":{"type":"string"},"RightID":{"type":"string","maxLength":60},"SubType":{"type":"string","maxLength":60},"TemplateType":{"type":"string","maxLength":10},"TranslationRule":{"type":"string","maxLength":1},"WellKnownID":{"type":"string","maxLength":50}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$XeCorrespondenceInfo.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$XeCorrespondenceInfo.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

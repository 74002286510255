/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail$XeCorrespondenceData.json#"},"CorrespondenceDataID":{"type":"integer","primaryKey":"generated"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"LetterSection":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"TokenName":{"type":"string","maxLength":500},"TokenValue":{"type":"string","maxLength":2000},"VisitCorrespondenceID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail$XeCorrespondenceData.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail$XeCorrespondenceData.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment$XeVisitCaseManagementProgram.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment$ParentID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IVID":{"type":"integer","primaryKey":"generated"},"IsActiveStatus":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ParentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment$ParentID.json#"},"RestrictValue":{"maximum":99999,"type":"integer"},"StartDate":{"format":"local-date-time","nullable":true,"type":"string"},"StartDateOff":{"type":"string"},"VisitID":{"type":"string","maxLength":60},"VisitStatusID":{"type":"string"},"VisitTypeID":{"type":"string","maxLength":16},"XeVisitCaseManagementProgram":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment$XeVisitCaseManagementProgram.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment$ParentID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment$ParentID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

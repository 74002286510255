/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment$ValidationResult.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment$ValidationTypeID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment$XeVisitAssessmentValidation.json#"},"AcknowledgedBy":{"type":"integer"},"AcknowledgedComment":{"type":"string","maxLength":256},"AcknowledgedDateTime":{"format":"date-time","nullable":true,"type":"string"},"AlertLevel":{"type":"string","maxLength":1},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IsAckRequired":{"type":"boolean"},"IsAcknowledged":{"type":"boolean"},"IsProceedOnAck":{"type":"string","maxLength":1},"Message":{"type":"string","maxLength":100},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ValidationDateTime":{"format":"date-time","nullable":true,"type":"string"},"ValidationID":{"type":"integer","primaryKey":"generated"},"ValidationResult":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment$ValidationResult.json#"},"ValidationTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment$ValidationTypeID.json#"}]},"VisitAssessmentID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment$XeVisitAssessmentValidation.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment$XeVisitAssessmentValidation.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$XeLanguage.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$XeCorrespondenceInfo.json#"},"Active":{"type":"boolean"},"AllowAttachment":{"type":"boolean"},"AllowCoverSelect":{"type":"boolean"},"CorrespondenceCode":{"type":"string","maxLength":50},"CorrespondenceInfoID":{"type":"integer","primaryKey":"supplied"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DefaultFaxCoverWKID":{"type":"string","maxLength":50},"DefaultMailCoverWKID":{"type":"string","maxLength":50},"Description":{"type":"string","maxLength":500},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"IsQuickLetter":{"type":"boolean"},"IsSelectable":{"type":"boolean"},"LetterType":{"type":"string","maxLength":60},"LineOfBusiness":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":100},"ProgramBits":{"maximum":9007199254740991,"type":"integer"},"ReportCode":{"type":"string","maxLength":50},"ReportID":{"type":"integer"},"RightID":{"type":"string","maxLength":60},"RightsBase":{"type":"string","maxLength":16},"SignatureRoleID":{"type":"string","maxLength":60},"SubType":{"type":"string","maxLength":60},"TemplateType":{"type":"string","maxLength":10},"ValidationTest":{"type":"string","maxLength":2147483647},"WellKnownID":{"type":"string","maxLength":50},"XeLanguage":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$XeLanguage.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$XeCorrespondenceInfo.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$XeCorrespondenceInfo.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListBrowseQuery.json#"},"CategoryID":{"type":"string","maxLength":16},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IsActiveStatus":{"type":"boolean"},"IsInError":{"type":"boolean"},"IsParentVisit":{"type":"boolean"},"IsShiftProvider":{"type":"boolean"},"IsVisitOpen":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PatientTypeID":{"type":"integer"},"PreferredDateTimeMax":{"format":"date-time","nullable":true,"type":"string"},"PreferredDateTimeMin":{"format":"date-time","nullable":true,"type":"string"},"ProgramID":{"type":"string","maxLength":16},"QuerySort":{"type":"string"},"ResourceID":{"items":{"type":"integer"},"type":"array"},"StatusID":{"type":"string","maxLength":16},"StatusIDOperator":{"type":"string"},"SubCategoryID":{"type":"string","maxLength":30},"VisitTypeID":{"type":"string","maxLength":16},"WaitListID":{"type":"integer","primaryKey":"generated"},"WaitListSubCategoryID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListBrowseQuery.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListBrowseQuery.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList$SignedBy.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList$XeVisitAssessmentVersion.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IsInError":{"defaultValue":false,"type":"boolean"},"IsVersionable":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ReportID":{"type":"integer"},"RestrictValue":{"maximum":99999,"type":"integer"},"SignedBy":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList$SignedBy.json#"}]},"SignedDate":{"format":"date-time","nullable":true,"type":"string"},"Version":{"maximum":9999,"type":"integer"},"VersionID":{"type":"integer","primaryKey":"generated"},"VisitAssessmentID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList$XeVisitAssessmentVersion.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList$XeVisitAssessmentVersion.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

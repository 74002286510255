/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$IPID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"CurrentCaseIVID":{"type":"integer"},"CurrentCaseProgramBits":{"type":"integer"},"DateOfDeath":{"format":"local-date-time","nullable":true,"type":"string"},"DateOfDeathOff":{"type":"string"},"DoB":{"format":"local-date-time","nullable":true,"type":"string"},"DoBOff":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IPID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"type":"string","maxLength":16},"PatientID":{"type":"string","maxLength":60},"PreferredName":{"type":"string","maxLength":100},"SecondName":{"type":"string","maxLength":100},"SexID":{"type":"string","maxLength":16},"Suffix":{"type":"string","maxLength":10}},"$id":"com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$IPID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML$IPID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$XeAuth.json#"},"AuthNum":{"type":"string","maxLength":30},"AuthTypeID":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IVID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NotifyMemberDueDate":{"format":"date-time","nullable":true,"type":"string"},"NotifyProviderDueDate":{"format":"date-time","nullable":true,"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$XeAuth.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$XeAuth.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

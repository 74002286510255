/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList$XeVisitCaseManagementProgram.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ProgramID":{"type":"string","maxLength":30},"ProgramIVID":{"type":"integer","primaryKey":"supplied"},"TableIdentifier":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList$XeVisitCaseManagementProgram.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList$XeVisitCaseManagementProgram.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter$XeVisitCorrespRecip.json#"},"Active":{"type":"boolean"},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"AutoSendTimeout":{"format":"date-time","nullable":true,"type":"string"},"City":{"type":"string","maxLength":100},"CountryID":{"type":"string","maxLength":16},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DeliveryStatus":{"type":"string","maxLength":16},"Fax":{"type":"string","maxLength":30},"GeneratedDateTime":{"format":"date-time","nullable":true,"type":"string"},"IsFaxNumberAuthorized":{"type":"string","maxLength":1},"IsPatient":{"type":"boolean"},"IsProvider":{"type":"boolean"},"LanguageID":{"type":"string","maxLength":16},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":200},"Phone":{"type":"string","maxLength":30},"ProviderTypeID":{"type":"string","maxLength":30},"ResourceID":{"type":"integer"},"ResourceTypeID":{"type":"string","maxLength":50},"RightsBase":{"type":"string","maxLength":16},"SendMethod":{"type":"string","maxLength":60},"SendMethodDetail":{"type":"string","maxLength":60},"SentDateTime":{"format":"date-time","nullable":true,"type":"string"},"StateProvince":{"type":"string","maxLength":100},"StatusID":{"type":"string","maxLength":16},"TrnVendorReqRtnHours":{"maximum":9999,"type":"integer"},"VisitCorrespRecipID":{"type":"integer","primaryKey":"generated"},"ZipPostalCode":{"type":"string","maxLength":20}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter$XeVisitCorrespRecip.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter$XeVisitCorrespRecip.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

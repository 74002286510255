/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequest$DiagnosisCode.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequest$ServiceCode.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequest.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DiagnosisCode":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequest$DiagnosisCode.json#"},"type":"array"},"IVID":{"type":"integer"},"IsDocumentation":{"type":"boolean"},"LinkID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ServerUrl":{"type":"string"},"ServiceCode":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequest$ServiceCode.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequest.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest$XeCorrespInfoRecip.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.visitcorrespattach.VisitCorrespondenceAttachResponse.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest$XeVisitCorrespParameter.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest.json#"},"AuthorID":{"type":"integer"},"BookID":{"type":"string","maxLength":64},"CorrespondenceCode":{"type":"string"},"CorrespondenceInfoID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"FaxBookID":{"type":"string","maxLength":64},"FaxCoverCorrespCode":{"type":"string"},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IsLocked":{"type":"boolean"},"LetterType":{"type":"string","maxLength":60},"LineOfBusiness":{"type":"string","maxLength":60},"MailBookID":{"type":"string","maxLength":64},"MailCoverCorrespCode":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"SignerID":{"type":"integer"},"SubType":{"type":"string","maxLength":60},"VisitCorrespondenceID":{"type":"integer","primaryKey":"generated"},"WellKnownID":{"type":"string"},"XeCorrespInfoRecip":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest$XeCorrespInfoRecip.json#"},"type":"array"},"XeVisitCorrespAttach":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorrespattach.VisitCorrespondenceAttachResponse.json#"},"type":"array"},"XeVisitCorrespParameter":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest$XeVisitCorrespParameter.json#"},"type":"array"}},"required":["IPID"],"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

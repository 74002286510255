/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$ParentID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$VisitTypeID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$XeVisitCaseManagementProgram.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$IVID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DerivedCaseIVID":{"type":"string"},"DerivedCaseVisitID":{"type":"string"},"DerivedProgram":{"type":"string"},"DerivedProgramID":{"type":"string"},"DerivedProgramIVID":{"type":"string"},"DerivedProgramVisitID":{"type":"string"},"IPID":{"type":"integer","primaryKey":"generated"},"IVID":{"type":"integer","primaryKey":"generated"},"IVIDOperator":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ParentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$ParentID.json#"},"ParentIDTableIdentifier":{"type":"string"},"RestrictValue":{"maximum":99999,"type":"integer"},"StartDate":{"format":"local-date-time","nullable":true,"type":"string"},"StartDateOff":{"type":"string"},"TableIdentifier":{"type":"string"},"VisitID":{"type":"string","maxLength":60},"VisitTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$VisitTypeID.json#"},"XeVisitCaseManagementProgram":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$XeVisitCaseManagementProgram.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$IVID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence$IVID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

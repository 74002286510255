/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$FaxCoverBookID.json#"},"BookID":{"type":"string","primaryKey":"generated","maxLength":64},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"XeSmartBookInstance":{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$FaxCoverBookID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$FaxCoverBookID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

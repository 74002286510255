/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$FileID.json#"},"FileID":{"type":"integer","primaryKey":"supplied"},"FileName":{"type":"string","maxLength":200}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$FileID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$FileID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$XeSmartBook.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$FaxCoverCustomLanguageID.json#"},"Active":{"type":"boolean"},"Asset":{"type":"string"},"CorrespondenceInfoID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"CustomLanguageCode":{"type":"string","maxLength":50},"CustomLanguageID":{"type":"integer","primaryKey":"supplied"},"Description":{"type":"string","maxLength":500},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"EnterpriseID":{"type":"string"},"InsertMessageID1":{"type":"string","maxLength":200},"InsertMessageID2":{"type":"string","maxLength":200},"InsertMessageID3":{"type":"string","maxLength":200},"InsertMessageID4":{"type":"string","maxLength":200},"LanguageID":{"type":"string","maxLength":16},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"SequencePosition":{"maximum":99999999,"type":"integer"},"SmartBookWKID":{"type":"string","maxLength":50},"XeSmartBook":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$XeSmartBook.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$FaxCoverCustomLanguageID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec$FaxCoverCustomLanguageID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$LetterType.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$SubType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$CorrespondenceInfoID.json#"},"CorrespondenceCode":{"type":"string","maxLength":50},"CorrespondenceInfoID":{"type":"integer","primaryKey":"supplied"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IsSelectable":{"type":"boolean"},"LetterType":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$LetterType.json#"},"LineOfBusiness":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"ProgramBits":{"maximum":9007199254740991,"type":"integer"},"ReportCode":{"type":"string","maxLength":50},"ReportFileName":{"type":"string"},"SubType":{"$ref":"com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$SubType.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$CorrespondenceInfoID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail$CorrespondenceInfoID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};

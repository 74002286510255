/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDBProcessMailRequest$XeDashboard.json#"},"Background":{"type":"string"},"CorrespondenceInfoID":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DashboardID":{"type":"string","primaryKey":"supplied","maxLength":50},"IPID":{"type":"string"},"IVID":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"SignerID":{"type":"string"},"VisitAssessmentID":{"type":"string"},"VisitCorrespondenceID":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDBProcessMailRequest$XeDashboard.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDBProcessMailRequest$XeDashboard.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
